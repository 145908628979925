.h1-searchpage{
    font-size: 1.5em !important;
    font-weight: bold;
    color: #000000;
    margin-top: 1em;
    margin-bottom: 1em;
}

.ad-list-link{
    color: #000000;
    text-decoration: none;
}

.dark-link{
    color: #000000 !important;
    text-decoration: none !important;
    font-weight: bold !important; 
    transition: color 0.3s ease, text-decoration 0.3s ease !important; 
}

.dark-link:hover {
    color: #555555 !important; 
    text-decoration: none !important;
}

.light-blue-link{
    color: #023047 !important;
    text-decoration: none !important;
    font-weight: bold !important; 
    transition: color 0.3s ease, text-decoration 0.3s ease !important; 
}
.light-blue-link:hover {
    color: #1d3557 !important; 
    text-decoration: none !important;
}

.signature-blue-link-profile-page{
    color: #023047 !important;
    text-decoration: none !important;
    font-weight: bold !important; 
    transition: color 0.3s ease, text-decoration 0.3s ease !important; 
}

.signature-blue-link-profile-page:hover {
    color: #04415f !important; 
    text-decoration: none !important;
}

.signature-blue-link-search-ad{
    color: #023047 !important;
    text-decoration: none !important;
    font-weight: bold !important; 
    transition: color 0.3s ease, text-decoration 0.3s ease !important; 

    position: absolute;
    bottom: 10px;
    right: 10px;
}

.signature-blue-link-search-ad:hover {
    color: #04415f !important; 
    text-decoration: none !important;
}

.black-text{
    color: #000000 !important;
}



.text-banner-frontpage{
    font-size: 1.15em !important;
}


/* .navbar .nav-link,
.navbar .dropdown-item {
  color: black !important;
} */

/* .navbar .nav-link:hover,
.navbar .dropdown-item:hover {
     background-color: #F0813D !important;
      
      border: 1px solid #033e5c !important; 
      background-color: #033e5c   !important; 
    color: #ffffff !important; 
  }

.navbar .dropdown-toggle {
    
  color: black !important; 
} */


.navlinktext {
    color: #000000 !important;
    /* font-weight: bold !important;  */
    transition: color 0.3s ease, text-decoration 0.3s ease !important; 
}