.search-form-frontpage-button {
    /* background-color: #2f6196 !important; */
    /* background-color: #c15f3d !important; */
    /* background-color: #cf6929 !important; */
    /* background-color: #db7535 !important;*/
    
    background-color: #023047  !important; 
    
    color: white;
    border: none !important;
    padding: 10px 20px;
    border-radius: 3px !important;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-form-frontpage-button:hover {
    /* background-color: #1a3d6d !important; */
    /* background-color: #c86b4c !important; */
    /* background-color: #F0813D !important; */
    background-color: #04415f  !important;
}

.navbar-postad-button {
  /* background-color: #2f6196 !important; */
  /* background-color: #c15f3d !important; */
  /* background-color: #cf6929 !important; */
  /* background-color: #db7535 !important;*/
  
  background-color: #023047 !important; 
  
  color: white;
  border: none !important;
  padding: 10px 20px;
  border-radius: 3px !important;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar-postad-button:hover {
  border: none !important;
  /* background-color: #1a3d6d !important; */
  /* background-color: #c86b4c !important; */
  /* background-color: #F0813D !important; */
  background-color: #04415f !important;
}

.custom-file-input {
    display: flex;
    align-items: center;
  }
  
  .custom-file-input input[type="file"] {
    display: none;
  }
  
  .custom-file-input .file-name {
    margin-left: 10px;
    font-size: 0.9em;
    color: #6c757d;
  }


  .signin-adpage {
    /* background-color: #2f6196 !important; */
    background-color: #023047!important;
    color: white;
    border: none !important;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50% !important;
  
}

.signin-adpage:hover {
    /* background-color: #1a3d6d !important; */
    background-color: #04415f  !important;
}

.signin-profilepage {
    /* background-color: #2f6196 !important; */
    background-color: #023047!important;
    color: white;
    border: none !important;
    padding: 7px 14px !important;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

  
}
.signin-profilepage:hover {
    /* background-color: #1a3d6d !important; */
    background-color: #04415f  !important;
}

.salgsannoncer-button {
  background-color: white !important;
  color: black !important;
  border: 2px solid #023047 !important;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  position: absolute;
  bottom: 10px;
  right: 10px;
}

.salgsannoncer-button-container {
  position: relative;
}

.salgsannoncer-button:hover {
  background-color: #fcfcfc !important;
  color: #023047  !important;
}


.nav-profile-buttons{
  /* border: 1px solid #db7535 !important; */
  /* border: 1px solid #023047 !important; */
  background-color: #023047 !important;
  border-radius: 3px !important;
  margin: 6px !important;
  font-size: 0.9em !important;
  /* padding: 18px !important; */

  color: white !important
  
}

.nav-profile-buttons:hover{
  /* background-color: #db7535 !important; */
  background-color: #04415f !important;
  /* border: 1px solid #023047 !important; */
  color: white !important;
}


.ad-item-edit-button {
  background-color: #023047 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ad-item-edit-button:hover {
  background-color: #04415f !important;
  color: white !important;
  border: none !important;
}

.ad-item-delete-button {
  background-color: #e63946 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ad-item-delete-button:hover {
  background-color: #d62839 !important;
  color: white !important;
  border: none !important;
}