.red-text-meta{
    color: rgb(192, 26, 26) !important;
}

.text-meta-form{
    /* color: rgb(221, 0, 0) !important; */
    /* color: #457b9d !important */
    color: #e63946 !important;
    font-weight: 700 !important;
    font-size: 0.9rem !important;
    line-height: 1.42 !important;
    
}

.blue-meta-form{
    color: #326dd9 !important;
    font-weight: 600 !important;
}

.red-text-frontpage-banner{
    color: rgb(240, 7, 7) !important;
}

.frontpage-banner-textspot {
    background-color: rgb(255, 255, 255) !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85% !important;
    border-top-right-radius: 10px; 
}


.relativ-div{
    position: relative !important;
    /* height: 100% !important; */
}

.relativ-div-frontpage{
    position: relative !important;
     height: 100% !important; 
}


.background-red{
    background-color: rgb(255, 255, 255) !important;
}

.background-color-frontpage{
    background-color: #faf9f5 !important;

}

.search-form-frontpage-button{
   background-color:  #c15f3d !important;
}

.logo-orange{
    /* color: #c15f3d !important; */
    color: #F0813D !important;
}

.dropdown-colors-navbar:hover {
    background-color: #F0813D !important;
    color: black !important;
}
