@import url("./cssfiles/utilities.css");
@import url("./cssfiles/colors.css");
@import url("./cssfiles/buttons.css");
@import url("./cssfiles/text.css");
@import url("./cssfiles/adpage.css");
@import url("./cssfiles/footer.css");

/* .App {
  text-align: center;
} */

/* .Container{
  max-width: 150px !important;
} */

.form-frontpage-background {
  /* background-color: #f5f9fa; #f0eee6 */
  /* background-color: #f0eee6b9 !important;  */
  /* background-color: #d1d4d8b9 !important; */
  /* background-color: #eeeeee85 !important;  */




  /* #f6faff #f5f9fa  !important */
  background-color: #f2f7f9 !important;
  /* background-color: rgb(250 250 250) !important; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

  
  /* border: thin solid rgb(208, 200, 200);  */
}

.form-searchpage-background {
  /* background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  background-color: #eeeeee85  !important;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* border: thin solid rgb(208, 200, 200);  */
}

.banner-frontpage-background {
  position: relative;
  overflow: hidden;
  background-image: url("./images/baggrundforside.jpg") !important;
  background-size: cover;
  background-position: center 50%; 
  height: 100% !important;
  border-radius: 5px !important;
}

.banner-frontpage-background::before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: blur(5px);
  z-index: 1; */
}

/* .banner-frontpage-background .content {
  position: relative;
  z-index: 2;
  color: white; 
  padding: 20px; 
} */

#nav-bar-top-width {
  max-width: 1050px !important;
}

@media only screen and (max-width: 800px) {
  .search-form .form-control {
    width: 400px; /* Adjust the width as needed */
  }
}

@media only screen and (min-width: 801px) {

  .search-form .form-control {
    width: 500px; /* Adjust the width as needed */
  }
}

.pop-books-color {
  background-color: rgb(255, 255, 255) !important;
  border: 2px double #dfe9f5 !important;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}
.pop-books-color img {
  border-radius: 5px;
  margin-bottom: 10px;
}

.pop-books-color p {
  font-size: 1rem;
  color: #333;
  margin: 0;
}

.pop-books-color:hover {
  transform: scale(1.02);
}



.container{
  max-width: 1250px !important;
}
