.adpage-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .adpage-image {
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .adpage-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .adpage-author {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  .adpage-back-button {
    margin-top: 20px;
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .adpage-back-button:hover {
    background-color: #0056b3;
    border-color: #004085;
  }


  .adpage-seller-info {
    padding: 5px;
    background-color: #f8f9fa;
    border: 2px solid black; 
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
  }